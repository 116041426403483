import React from 'react';
import Logo from "../../assets/images/invalid_url.svg";
import Caution from "../../assets/images/call.svg";
import './error.css';

const InvalidUrl = () => {
    return(
        <div className="Container">
            <img src={Logo} alt={'Logo'} className="img"/>
            <p className="err-title">
                Aap jis URL tak pahunchane ka prayaas
                <br/>
                kar rahe hain vah amanya hai.
            </p>
            <p className="sub-title">
                kripaya dubaara prayas karen.
            </p>
            <div className="divider" />
            <p className="err-sub-title">
                Adhik Jankari ke liye neeche likhe number per <br/>Chefkart grahak seva se sampark karen.
            </p>
            <a href="tel:+91-8035337858" className="card err-card err-link">
                <div className="cau-img-container">
                    <img src={Caution} alt={'Caution'} className="cau-img" />
                </div>
                <p className="sub-title-2">
                    <b>{'+91-8035337858'}</b>
                </p>
            </a>
        </div>
    )
};

export default InvalidUrl;
