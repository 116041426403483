import axios from 'axios';

const HttpClientUnauthenticated = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'X-APP-CODE': 'COOK',
  },
});

export default HttpClientUnauthenticated;
