import {OmsActionType} from "../actions/omsReducerActions/omsActionType";
import AcceptOmsInterface from "../../interfaces/oms/AcceptOmsInterface";


export interface OmsReducerState {
    acceptOms: AcceptOmsInterface | null;
}

const initialState: OmsReducerState = {
    acceptOms: null,
};

export const omsReducer = (
    state: OmsReducerState = initialState,
    {type, payload}: {type: OmsActionType; payload: any},
): OmsReducerState => {
    switch (type) {
        case OmsActionType.ACCEPT_OMS:
            return {
                ...state,
                acceptOms: payload,
            };
        default:
            return state;
    }
};
