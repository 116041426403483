// Import the functions you need from the SDKs you need
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfigProduction = {
    apiKey: "AIzaSyAddiMrf-yNN0bf6sl1Pb_4FFn9X45HUa0",
    authDomain: "andromeda-c7387.firebaseapp.com",
    projectId: "andromeda-c7387",
    storageBucket: "andromeda-c7387.appspot.com",
    messagingSenderId: "851865888295",
    appId: "1:851865888295:web:3ed21c0fe3f61a38361002",
    measurementId: "G-B8T9BX35ZS"
}

const firebaseConfigStaging = {
  apiKey: "AIzaSyBveYiBvUbBZlsd99XNTalaf_YanoOMiAQ",
  authDomain: "andromeda-staging-e89ce.firebaseapp.com",
  projectId: "andromeda-staging-e89ce",
  storageBucket: "andromeda-staging-e89ce.appspot.com",
  messagingSenderId: "757310657461",
  appId: "1:757310657461:web:d6f67ac20ceb383352fffc",
  measurementId: "G-LDM58Z6BPZ"
};

const firebaseConfig = process.env.REACT_APP_ENV === 'production' ? firebaseConfigProduction : firebaseConfigStaging;

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);
