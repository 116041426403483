import HttpClientUnauthenticated from '../../../HttpClientUnauthenticated';
import store from '../../store';
import {OmsActionType} from './omsActionType';
import AcceptOmsInterface from "../../../interfaces/oms/AcceptOmsInterface";

const baseURL = '/cooks/v3/one-meal-service'

export const acceptOms = async (omsId: string | null, accessToken: string | null): Promise<AcceptOmsInterface> => {
  const response = await HttpClientUnauthenticated.post(`${baseURL}/${omsId}/accept-lead`, {}, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    }
  });
  store.dispatch({
    type: OmsActionType.ACCEPT_OMS,
    payload: response.data,
  });
  return response?.data;
};
