import React from 'react';
import Logo from '../../assets/images/thumb.svg';
import Caution from '../../assets/images/caution.svg';
import './landing.css';

const Success = () => {
  return(
      <div className="Container">
          <img src={Logo} alt={'Logo'} className="Image"/>
          <p className="title">
              काम करने का अपना इरादा साझा
              <br/>
              करने के लिए धन्यवाद
          </p>
          <p className="sub-title">
              इस काम की पुष्टि करने के लिए हम आपको फोन करेंगे
          </p>
          <div className="divider" />
          <div className="card">
              <div className="cau-img-container">
                  <img src={Caution} alt={'Caution'} className="cau-img" />
              </div>
              <div>
                  <p className="sub-title-1">
                      कृपया इस काम पर हमारे द्वारा पुष्टि किए
                  </p>
                  <p className="sub-title-2">
                      जाने के बाद ही जाएं, धन्यवाद
                  </p>
              </div>
          </div>
      </div>
  )
};

export default Success;
