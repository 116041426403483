import {combineReducers} from 'redux';
import {trailReducer, TrialReducerState} from "./trailReducer";
import {omsReducer, OmsReducerState} from "./omsReducer";

export interface RootReducer {
  acceptTrial: TrialReducerState;
  oms: OmsReducerState;
}

const reducers = combineReducers({
  trail: trailReducer,
  oms: omsReducer,
});

export default reducers;
