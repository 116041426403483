import {
    legacy_createStore as createStore,
    applyMiddleware,
    compose,
} from 'redux';
import reducers from './reducers';

const middlewares = [];

if (process.env.REACT_APP_ENV === `development`) {
    const { logger } = require(`redux-logger`);

    middlewares.push(logger);
}

// const store = compose(applyMiddleware(...middlewares))(createStore)(reducers);
const store = createStore(reducers, compose(applyMiddleware(...middlewares)));

export default store;
