import React, {useEffect, useMemo, useState} from 'react';
import Logo from '../../assets/images/thumb.svg';
import Caution from '../../assets/images/caution.svg';
import './landing.css';
import { useLocation, useNavigate } from "react-router-dom";
import {acceptTrial} from "../../redux/actions/trialReducerActions/trialActions";

const TrialAcceptance = () => {
    const navigate = useNavigate();
    const { search } = useLocation();
    const query = useMemo(() => new URLSearchParams(search), [search]);
    const [loading, setLoading] = useState(true);
    useEffect(()=>{
        setLoading(true);
        acceptTrial(query.get('trialId'), query.get('token'))
            .then(() => {
                setLoading(false);
            })
            .catch(err => {
                if(err && err.response && err.response.status && err.response.status === 409){
                    // redirect to success page
                    navigate('/success', { replace: true });
                } else if(err && err.response && err.response.status && err.response.status === 436){
                    // redirect to no available page
                    navigate('/not-available', { replace: true });
                } else {
                    // redirect to invalid page
                    navigate('/invalid', { replace: true });
                }
            })
    },[query, navigate])
  return(
      <>
          {!loading ? (
              <div className="Container">
                  <img src={Logo} alt={'Logo'} className="Image"/>
                  <p className="title">
                      काम करने का अपना इरादा साझा
                      <br/>
                      करने के लिए धन्यवाद
                  </p>
                  <p className="sub-title">
                      इस काम की पुष्टि करने के लिए हम आपको फोन करेंगे
                  </p>
                  <div className="divider" />
                  <div className="card">
                      <div className="cau-img-container">
                          <img src={Caution} alt={'Caution'} className="cau-img" />
                      </div>
                      <div>
                          <p className="sub-title-1">
                              कृपया इस काम पर हमारे द्वारा पुष्टि किए
                          </p>
                          <p className="sub-title-2">
                              जाने के बाद ही जाएं, धन्यवाद
                          </p>
                      </div>
                  </div>
              </div>) :
              <div className="Container">
                  <div className="loader"/>
              </div>
          }
      </>
  )
};

export default TrialAcceptance;
