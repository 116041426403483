import './App.css';
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import TrialAcceptance from "./components/Home/TrialAcceptance";
import InvalidUrl from "./components/Error/InvalidUrl";
import NotAvailable from "./components/Error/NotAvailable";
import Success from "./components/Home/Success";
import OmsAcceptance from "./components/Home/OmsAcceptance";

function App() {
  return (
      <BrowserRouter>
          <Routes>
              <Route path="/" element={<TrialAcceptance />} />
              <Route path="/trial" element={<TrialAcceptance />} />
              <Route path="/oms" element={<OmsAcceptance />} />
              <Route path="success" element={<Success />} />
              <Route path="invalid" element={<InvalidUrl />} />
              <Route path="not-available" element={<NotAvailable />} />
          </Routes>
      </BrowserRouter>
  );
}

export default App;
