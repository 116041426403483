import HttpClientUnauthenticated from '../../../HttpClientUnauthenticated';
import store from '../../store';
import {TrialActionTypes} from './trialActionType';
import AcceptTrialInterface from "../../../interfaces/trail/AcceptTrialInterface";

const baseURL = '/cooks/v3/trials'

export const acceptTrial = async (trialId: string | null, accessToken: string | null): Promise<AcceptTrialInterface> => {
  const response = await HttpClientUnauthenticated.post(`${baseURL}/${trialId}/accept-lead`, {}, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    }
  });
  store.dispatch({
    type: TrialActionTypes.ACCEPT_TRAIL,
    payload: response.data,
  });
  return response?.data;
};
