import {TrialActionTypes} from "../actions/trialReducerActions/trialActionType";
import AcceptTrialInterface from "../../interfaces/trail/AcceptTrialInterface";


export interface TrialReducerState {
    acceptTrail: AcceptTrialInterface | null;
}

const initialState: TrialReducerState = {
    acceptTrail: null,
};

export const trailReducer = (
    state: TrialReducerState = initialState,
    {type, payload}: {type: TrialActionTypes; payload: any},
): TrialReducerState => {
    switch (type) {
        case TrialActionTypes.ACCEPT_TRAIL:
            return {
                ...state,
                acceptTrail: payload,
            };
        default:
            return state;
    }
};
